import { vOutput } from '../validation'
import {
  vBoolean,
  vCoerceArray,
  vObject,
  vOptional,
  vPick,
  vString,
} from '../validation/schema/core'
import { readyCompletedFilterSchema } from './shipment-filters'

export const adminExportAccountLoadsSchema = vObject({
  ...vPick(readyCompletedFilterSchema, ['mode', 'pickupDate']).entries,
  excludeFedEx: vOptional(vBoolean()),
  emails: vCoerceArray(vString()),
})
export type AdminExportAccountLoadsDto = vOutput<
  typeof adminExportAccountLoadsSchema
>
